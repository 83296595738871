var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ServicesBase {
    getAuthorizationServer() {
        return __awaiter(this, void 0, void 0, function* () {
            const manifest = yield this.getManifestData();
            if (manifest.authorization_server) {
                return manifest.authorization_server;
            }
            const url = yield this.getBaseUrl();
            return url + '/rest/as';
        });
    }
    getApplicationRoot() {
        if (this._application_root) {
            return this._application_root;
        }
        // Use <base href="" /> from index.html if set
        if (document.baseURI) {
            return (this._application_root = this._stripDownToFolder(document.baseURI));
        }
        const location = document.location;
        let baseUrl = location.protocol;
        baseUrl += '//';
        baseUrl += location.hostname;
        if (location.port) {
            baseUrl += ':' + location.port;
        }
        if (location.hostname === '127.0.0.1' || location.hostname === 'localhost') {
            baseUrl += '/';
        }
        else {
            baseUrl += location.pathname.replace(/\/[^/]*$/, '');
        }
        return (this._application_root = baseUrl);
    }
    _stripDownToFolder(url) {
        const qpos = url.indexOf('?');
        if (qpos >= 0) {
            url = url.substr(0, qpos);
        }
        return url.replace(/\/\w*?[.]html$/, '');
    }
    getGraphQLEndpoint(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this.getBaseUrl();
            return url + '/graphql/' + id;
        });
    }
    getRestBaseUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.getBaseUrl()) + '/rest';
        });
    }
    getServerDispatcherUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this.getBaseUrl();
            return url + '/rest/serverdispatcher/url';
        });
    }
    getStaticUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const manifest = yield this.getManifestData();
            if (manifest.static_url) {
                return manifest.static_url;
            }
            return '';
        });
    }
    getVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            const manifest = yield this.getManifestData();
            return manifest.version;
        });
    }
    getWebSocketUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const manifest = yield this.getManifestData();
            return manifest.websocket_url;
        });
    }
    _getBaseUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const manifest = yield this.getManifestData();
            if (manifest.service_base_url !== undefined) {
                return manifest.service_base_url;
            }
            throw new Error('Mangler service_base_url');
        });
    }
    getManifestData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._manifest) {
                return this._manifest;
            }
            /*
            const loc = document.location;
            let base_url = loc.protocol;
            base_url += "//";
            base_url += loc.host;
            if (loc.port) {
                base_url += ":" + loc.port;
            }
            base_url += loc.pathname.replace(/\/[^/]*$/, "/");
            */
            const manifestUrl = this.getManifestUrl();
            //                CerumServices.getAjaxHandler().doAjax(manifest_url, null, delegate);
            const rawManifest = yield this.getAjaxHandler().doAjax(manifestUrl);
            try {
                const obj = JSON.parse(rawManifest);
                if (obj) {
                    return (this._manifest = obj);
                }
            }
            catch (exception) {
                console.error('Manifest lastet ned fra [' + manifestUrl + '] er ikke gyldig', {
                    exception,
                    raw_manifest: rawManifest,
                });
            }
            throw new Error('Får ikke tak i (gyldig) manifest fra [' + manifestUrl + ']');
        });
    }
    _setManifest(manifest) {
        this._manifest = manifest;
    }
    getManifestUrl() {
        const baseUrl = this.getApplicationRoot();
        console.log('Application root: ', baseUrl);
        const manifestUrl = baseUrl + '/manifest.json';
        console.log('Manifest url: ', manifestUrl);
        return manifestUrl;
    }
}
